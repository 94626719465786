<template>
  <div>
    <b-modal
      ref="my-modal-novo-procedimento"
      hide-footer
      centered
      id="modal-novo-procedimento"
      title="Novo procedimento"
    >
      <div>
        <validation-observer ref="observer" v-slot="{ invalid }">
          <form @submit.prevent="cadastrarNovoProcedimentoTipo">
            <div class="row">
              <div class="col-md-12 mt-4">
                <h6 style="color: grey">Descrição</h6>
                <validation-provider>
                  <v-text-field v-model="descricaoProcedimento"></v-text-field>
                </validation-provider>
              </div>
            </div>

            <v-btn
              style="float: right"
              @click="hideModal"
              class="textStyle mt-6"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Voltar</span>
            </v-btn>

            <v-btn
              :disabled="invalid"
              style="float: right"
              @click="cadastrarNovoProcedimentoTipo"
              class="mr-4 mt-6 textStyle"
              tile
              color="#1dd1a1"
            >
              <span style="color: white">Cadastrar</span>
            </v-btn>
          </form>
        </validation-observer>
      </div>
    </b-modal>

    <b-modal
      ref="filter-modal"
      hide-footer
      centered
      id="modal-dataProcedimentos"
      title="Filtrar procedimentos por intervalo de data"
    >
      <div class="row">
        <div class="col-md-5">
          <v-text-field v-model="consultaDataUm" type="Date"></v-text-field>
        </div>
        <div class="col-md-5">
          <v-text-field v-model="consultaDataDois" type="Date"></v-text-field>
        </div>
        <div class="col-md-2">
          <v-btn
            @click="consultarPorIntervalo"
            v-b-tooltip.hover.top="'Filtrar'"
            color="#1dd1a1"
            class="mr-4 mb-2 mt-4"
          >
            <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
          >
        </div>
      </div>
    </b-modal>

    <div class="mb-4">
      <!--begin::Dashboard-->
      <div class="row">
        <div style="background-color: rgb(29, 209, 161)" class="col-md-12">
          <h3 style="float: left" class="textTitile mt-4 mb-4">
            Histórico de Procedimentos
          </h3>

          <b-button
            variant="success"
            style="
              font-size: 1.2rem;
              margin-left: 1rem;
              margin-top: 0.2rem;
              border-radius: 0px;
              border: 2px solid white;
              color: white;
            "
            v-b-modal.modal-novo-procedimento
            >Novo procedimento</b-button
          >
        </div>
        <div class="col-md-12 mt-4">
          <v-toolbar style="height: 6rem">
            <v-toolbar-title
              ><v-btn
                @click="consultaProcedimentosResultado"
                color="#1dd1a1"
                class="mr-4 mb-2 mt-6"
              >
                <v-icon center small color="#FFF">fas fa-search</v-icon></v-btn
              ></v-toolbar-title
            >

            <v-text-field
              @keyup.enter.native="consultaProcedimentosResultado"
              v-model="consulta"
              class="mt-14"
              label="Pesquise consultas por Data, nome e tipo do procedimento"
              color="green"
              filled
              solo-inverted
            ></v-text-field>

            <v-btn
              v-b-modal.modal-dataProcedimentos
              v-b-tooltip.hover.top="'Filtrar por intervalo'"
              class="ml-4 mb-2 mt-6"
              color="#1dd1a1"
            >
              <span style="color: white"></span>
              <v-icon center small color="#FFF"
                >fas fa-calendar-alt</v-icon
              ></v-btn
            >
          </v-toolbar>

          <div class="row mt-6">
            <div
              style="
                display: flex;
                align-itens: center;
                justify-content: space-between;
              "
              class="col-md-12"
            >
              <b-tooltip :target="`tooltip3-target`" triggers="hover">
                Página anterior
              </b-tooltip>
              <b-tooltip :target="`tooltip4-target`" triggers="hover">
                Próxima página
              </b-tooltip>
              <div class="mt-2">
                <h3>Página {{ this.page }} de {{ this.totalPages }}</h3>
              </div>
              <div>
                <v-btn
                  :disabled="this.page == 0 || this.page == 1 ? true : false"
                  @click="anteriorPage"
                  id="tooltip3-target"
                  color="#1dd1a1"
                  class="mr-1"
                >
                  <v-icon center small color="#FFF"
                    >fas fa-arrow-left</v-icon
                  ></v-btn
                >
                <v-btn
                  :disabled="
                    this.page == this.totalPages || this.totalPages == 0
                      ? true
                      : false
                  "
                  @click="proximoPage"
                  id="tooltip4-target"
                  color="#1dd1a1"
                >
                  <v-icon center small color="#FFF"
                    >fas fa-arrow-right</v-icon
                  ></v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="">Procedimento</th>
                  <th class="">Data</th>
                  <th class="">Descrição</th>
                  <th class="">Atendido por</th>
                </tr>
              </thead>

              <tbody
                v-for="procedimento in tempProcedimentos"
                :key="procedimento.id"
              >
                <tr>
                  <td>
                    {{ procedimento.tipo_procedimento.descricao }}
                  </td>
                  <td>
                    {{ procedimento.data_procedimento }}
                  </td>

                  <td>
                    {{ procedimento.descricao }}
                  </td>

                  <td>
                    <strong style="color: green">{{
                      procedimento.atendido_por.nome
                    }}</strong>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div
            v-if="tempProcedimentos.length == 0"
            class="d-flex flex-column-fluid flex-center mt-12"
          >
            <a href="#" class="text-center mb-10">
              <img
                src="media/novaImagens/logo.png"
                class="max-h-200px"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>

    <div class="row">
      <div class="col-md-12">
        <!--begin::prontuario-->
        <div class="row">
          <div class="col-md-12">
            <div
              style="
                background-color: rgb(29, 209, 161);
                height: 3.8rem;
                padding: 0.5px;
              "
              class="col-md-12"
            >
              <h3 style="float: left" class="textTitile mt-4 ml-4">
                Dados de procedimentos
              </h3>
            </div>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-md-12">
            <validation-observer
              class="mt-6"
              ref="observer"
              v-slot="{ invalid }"
            >
              <form @submit.prevent="atualizarPaciente">
                <div class="row">
                  <div class="col-md-6 mt-4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Procedimento"
                      rules="required"
                    >
                      <b-form-select
                        style="border-color: black"
                        v-model="procedimento"
                        :error-messages="errors"
                        :options="options"
                        required
                      ></b-form-select>
                    </validation-provider>
                  </div>

                  <div class="col-md-6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Data do procedimento"
                      rules="required"
                    >
                      <v-text-field
                        type="date"
                        v-model="dataProcedimento"
                        label="Data do procedimento (Obrigatório)"
                        :error-messages="errors"
                      ></v-text-field>
                    </validation-provider>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Descrição"
                      rules="required"
                    >
                      <b-form-textarea
                        :error-messages="errors"
                        v-model="descricao"
                        placeholder="Descrição (Obrigatório)"
                        rows="3"
                        required
                        max-rows="6"
                      ></b-form-textarea>
                    </validation-provider>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-md-12">
                    <v-btn
                      v-if="dados.dados_prontuario.procedimento_existe == false"
                      @click="createProcedimento"
                      style="float: right"
                      :disabled="invalid"
                      class="mt-4 textStyle"
                      tile
                      color="#1dd1a1"
                    >
                      <span style="color: white">Salvar</span>
                    </v-btn>

                    <v-btn
                      v-else
                      @click="updateProcedimento"
                      style="float: right"
                      :disabled="invalid"
                      class="mt-4 textStyle"
                      tile
                      color="#1dd1a1"
                    >
                      <span style="color: white">Salvar</span>
                    </v-btn>
                  </div>
                </div>
              </form>
            </validation-observer>
          </div>
        </div>
        <!--end::prontuario-->
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import * as ambulatorio from "../../../core/services/ambulatorioAPI.service";

import { required } from "vee-validate/dist/rules";

import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "O campo {_field_} precisa ser preenchido",
});
export default {
  name: "prontuario",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dados: Object,
  },
  async created() {
    const { data } = await ambulatorio.historicoProcedimentos(
      this.dados.dados_prontuario.id,
      1
    );

    const {
      data: { tipo_procedimento },
    } = await ambulatorio.loadTiposProcedimentos();

    tipo_procedimento.map((proce) => {
      this.options = [
        ...this.options,
        { value: proce.id, text: proce.descricao },
      ];
    });

    this.procedimentos = data.historico_procedimentos;
    this.tempProcedimentos = data.historico_procedimentos;
    this.totalPages = data.total_pages;

    this.loadProcedimentos();
  },
  data() {
    return {
      descricaoProcedimento: "",
      consultaDataUm: "",
      consultaDataDois: "",
      idProcedimento: "",
      atendidoPor: "",
      procedimento: null,
      dataProcedimento: "",
      descricao: "",
      options: [
        { value: null, text: "Seleciona o tipo de procedimento (Obrigatório)" },
      ],
      totalPages: 1,
      page: 1,
      procedimentos: [],
      tempProcedimentos: [],
      consulta: "",
    };
  },

  methods: {
    cadastrarNovoProcedimentoTipo: async function () {
      try {
        const data = {
          tipo_procedimento: {
            descricao: this.descricaoProcedimento,
          },
        };

        await ambulatorio.addTipoProcedimento(data);

        this.options = [
          {
            value: null,
            text: "Seleciona o tipo de procedimento (Obrigatório)",
          },
        ];

        const {
          data: { tipo_procedimento },
        } = await ambulatorio.loadTiposProcedimentos();

        tipo_procedimento.map((proce) => {
          this.options = [
            ...this.options,
            { value: proce.id, text: proce.descricao },
          ];
        });

        this.hideModal();

        Swal.fire({
          title: "",
          text: "Procedimento adicionado com sucesso",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao adicionar um novo procedimento, verifique as infomrações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    createProcedimento: async function () {
      try {
        const data = {
          procedimento: {
            tipo_procedimento_id: this.procedimento,
            data_procedimento: this.dataProcedimento,
            descricao: this.descricao,
            prontuario_id: this.dados.dados_prontuario.id,
          },
        };

        await ambulatorio.createProcedimento(data);

        const response = await ambulatorio.historicoProcedimentos(
          this.dados.dados_prontuario.id,
          1
        );

        this.procedimentos = response.data.historico_procedimentos;
        this.tempProcedimentos = response.data.historico_procedimentos;
        this.totalPages = response.data.total_pages;

        Swal.fire({
          title: "",
          text: "Informações de procedimento salvas com sucesso!",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });
        this.procedimento = null;
        this.dataProcedimento = "";
        this.descricao = "";
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar salvamento, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    updateProcedimento: async function () {
      try {
        const data = {
          procedimento: {
            nome: this.procedimento,
            data_procedimento: this.dataProcedimento,
            descricao: this.descricao,
          },
        };

        await ambulatorio.updateProcedimentos(this.idProcedimento, data);

        const response = await ambulatorio.historicoProcedimentos(
          this.dados.dados_prontuario.id,
          1
        );

        this.procedimentos = response.data.historico_procedimentos;
        this.tempProcedimentos = response.data.historico_procedimentos;
        this.totalPages = response.data.total_pages;

        Swal.fire({
          title: "",
          text: "Informações de procedimento cadastradas com sucesso!",
          icon: "success",
          confirmButtonColor: "green",
          heightAuto: false,
        });

        this.procedimento = null;
        this.dataProcedimento = "";
        this.descricao = "";
      } catch (error) {
        console.log(error);
        Swal.fire({
          title: "",
          text: "Erro ao realizar cadastro, verifique as informações",
          icon: "error",
          confirmButtonColor: "red",
          heightAuto: false,
        });
      }
    },
    loadProcedimentos: async function () {
      try {
        const { data } = await ambulatorio.getByProntuarioProcedimentos(
          this.dados.dados_prontuario.id
        );

        this.idProcedimento = data.procedimento.id;
      } catch (error) {
        console.log(error);
      }
    },

    consultaProcedimentosResultado: async function () {
      if (this.consulta == "" || this.consulta == " ") {
        const { data } = await ambulatorio.historicoProcedimentos(
          this.dados.dados_prontuario.id,
          1
        );

        this.procedimentos = data.historico_procedimentos;
        this.tempProcedimentos = data.historico_procedimentos;
        this.totalPages = data.total_pages;
      } else {
        const data = {
          filter: {
            created_at: this.consulta,
            "historicos_procedimentos.nome": this.consulta,
          },
          required: {
            prontuario_id: this.dados.dados_prontuario.id,
          },
          page: 1,
          size: 10,
        };

        const response = await ambulatorio.buscaResultadoProcedimentos(data);

        this.procedimentos = response.data.resultado;
        this.tempProcedimentos = response.data.resultado;
        this.totalPages = response.data.total_pages;
      }
    },
    proximoPage: async function () {
      try {
        this.page++;
        if (this.page <= this.totalPages) {
          const response = await ambulatorio.historicoProcedimentos(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.procedimentos = response.data.historico_procedimentos;
          this.tempProcedimentos = response.data.historico_procedimentos;
          this.totalPages = response.data.total_pages;
        } else {
          this.page--;
        }
      } catch (error) {
        console.log(error);
      }
    },
    anteriorPage: async function () {
      try {
        this.page--;
        if (this.page >= 1) {
          const response = await ambulatorio.historicoProcedimentos(
            this.dados.dados_prontuario.id,
            this.page
          );
          this.procedimentos = response.data.historico_procedimentos;
          this.tempProcedimentos = response.data.historico_procedimentos;
          this.totalPages = response.data.total_pages;
        } else {
          this.page++;
        }
      } catch (error) {
        console.log(error);
      }
    },

    hideModal() {
      this.$refs["filter-modal"].hide();
      this.$refs["my-modal-novo-procedimento"].hide();
    },

    consultarPorIntervalo: async function () {
      try {
        const data = {
          filter: {
            created_at: "",
            "pessoas.nome": this.dados.dados_paciente.pessoa.nome,
            "prontuarios.numero": this.dados.dados_paciente.prontuario,
            "pessoas.cpf": this.dados.dados_paciente.pessoa.cpf,
          },
          required: {
            created_at: [this.consultaDataUm, this.consultaDataDois],
          },
          page: 1,
          size: 10,
        };

        const response = await ambulatorio.buscaResultadoProcedimentos(data);

        this.procedimentos = response.data.resultado;
        this.tempProcedimentos = response.data.resultado;
        this.totalPages = response.data.total_pages;

        this.hideModal();
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Prontuário " }]);
  },
};
</script>

<style>
.nav-link.active {
  color: rgb(29, 209, 161) !important;
  font-size: 1.1em;
}

.nav-link:hover {
  color: rgb(29, 209, 161) !important;
}

.bg-success {
  background-color: rgb(29, 209, 161) !important;
}

.textTitile {
  color: white;
  text-align: center;
}

.theme--light.v-messages {
  color: red !important ;
}
</style>
